import { useState } from 'react';
import style from './Modal.module.scss';
import closeSvg from './assets/close.svg';
import img from './assets/img.png';

const Modal = () => {
  const [isModalOpen, setModalOpen] = useState(true);

  const toggleVideo = () => {
    const video = document.querySelector('#main-video');
    setModalOpen(false);

    setTimeout(() => {
      video.muted = false;
      video.play();
    }, 1);
  };

  return (
    isModalOpen && (
      <div onClick={toggleVideo} className={style.wrapper}>
        <div className={style.modal}>
          <div className={style.modal__close}>
            <img src={closeSvg} alt='' />
          </div>

          <div className={style.modal__img}>
            <img src={img} alt='' />
          </div>
        </div>
      </div>
    )
  );
};

export default Modal;
