import style from './Dorku.module.scss';
import logo from './assets/logo.png';
import img from './assets/img.png';
import imgMobile from './assets/img-mobile.png';
import quote from './assets/quote.png';
import title from './assets/title.png';
import address from './assets/address.png';
import buy from './assets/buy.png';
import twitter from './assets/twitter.jpg';
import text from './assets/text.png';
import { useMediaQuery } from 'usehooks-ts';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useState } from 'react';

const DorkuSection = () => {
  const [copied, setCopied] = useState(false);
  const isMobile = useMediaQuery('(max-width:767px)');

  const toggleCopied = () => {
    setCopied(true);

    setTimeout(() => {
      setCopied(false);
    }, 1500);
  };

  return (
    <section className={style.about}>
      <div className={style.about__logo}>
        <img src={logo} alt='' />
      </div>
      <div className={style.about__img}>
        <img src={isMobile ? imgMobile : img} alt='' />
      </div>
      {/* <div className='container'>
      </div> */}
      <div className={style.aboutBody}>
        {/* <div className={style.aboutBody__twitter}>
          <img src={twitter} alt='' />
        </div> */}
        <div className={style.aboutBody__quote}>
          <img src={quote} alt='' />
        </div>
        <CopyToClipboard
          onCopy={toggleCopied}
          text='4BW7SUSgskvBhmHYkjhLaDjE3W1GSskMUWbVWa5gJcWH'
        >
          <div className={style.aboutBody__address}>
            <span className={copied && style.active}>Copied</span>
            {/* <img src={address} alt='' /> */}
            4BW7SUSgskvBhmHYkjhLaDjE3W1GSskMUWbVWa5gJcWH
          </div>
        </CopyToClipboard>
        <div className={style.aboutBody__title}>
          <img src={title} alt='' />
        </div>
        {/* <div className={style.aboutBody__subtitle}>ON SOLANA</div> */}
        {/* <a
          href='https://www.dextools.io/app/en/solana/pair-explorer/6V6bAiwHQGCjiNLSCUrmaRuAPd2xc66bWXdgtzAR8uJ7?t=1706904204242'
          target='_blank'
          rel='noreferrer'
          className={style.aboutBody__buy}
        >
          <img src={buy} alt='' />
        </a> */}
        <div className={style.aboutBody__text}>
          <img src={text} alt='' />
        </div>
      </div>
    </section>
  );
};

export default DorkuSection;
