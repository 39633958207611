import style from './About.module.scss';
import bg from './assets/bg.png';
import bgMobile from './assets/bg-mobile.png';
import text from './assets/text.png';
import tate from './assets/tate.png';
import tateMobile from './assets/tate_mobile.png';
import quote from './assets/quote.png';
import { useMediaQuery } from 'usehooks-ts';

const AboutSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.about}>
      <div className={style.about__img}>
        <img src={isMobile ? bgMobile : bg} alt='' />
      </div>
      <div className={style.about__text}>
        <img src={text} alt='' />
      </div>
      <div className={style.about__tate}>
        <img src={isMobile ? tateMobile : tate} alt='' />
      </div>
      <div className={style.about__quote}>
        <img src={quote} alt='' />
      </div>
    </section>
  );
};

export default AboutSection;
