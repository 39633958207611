import style from './Tokenomics.module.scss';
import bg from './assets/bg.png';
import bgMobile from './assets/bg-mobile.png';
import title from './assets/title.png';
import roadmapTitle from './assets/title_roadmap.png';
import quote from './assets/quote-first.png';
// import roadmapQuote from './assets/roadmap_quote.png';
import { useMediaQuery } from 'usehooks-ts';

const TokenomicsSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <section className={style.tokenomics}>
      <div className={style.tokenomics__img}>
        <img src={isMobile ? bgMobile : bg} alt='' />
      </div>
      <div className={style.tokenomics__title}>
        <img src={title} alt='' />
      </div>
      {/* <div className={style.tokenomics__quote}>
        <img src={quote} alt='' />
      </div> */}
      <div className={style.tokenomicsRoadmap__title}>
        <img src={roadmapTitle} alt='' />
      </div>
      <div className={style.tokenomicsRoadmap__quote}>
        <img src={quote} alt='' />
      </div>
    </section>
  );
};

export default TokenomicsSection;
