import style from './Main.module.scss';
import video from './assets/video.mp4';
import {useEffect, useRef} from 'react';

const MainSection = ({mainMuted}) => {

    const videoRef = useRef(null);

    useEffect(() => {
        videoRef.current.muted = mainMuted;
    }, [mainMuted])



    return (
        <main className={style.main}
        >
            <div className={style.main__img}>
                <video
                    id='main-video'
                    ref={videoRef}
                    src={video}
                    playsInline
                    webkit-playsInline
                    loop
                    autoPlay
                    muted
                    preload='none'
                ></video>
            </div>
        </main>
    );
};

export default MainSection;
