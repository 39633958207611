import style from './Footer.module.scss';
import tate from './assets/tate.png';
import quote from './assets/quote.png';
import bg from './assets/bg.jpg';
import bgMobile from './assets/bg-mobile.jpg';
import { useMediaQuery } from 'usehooks-ts';
import whitepaperPDF from './assets/whitepaper.pdf';

const Footer = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <footer className={style.footer}>
      <div className={style.footer__img}>
        <img src={isMobile ? bgMobile : bg} alt='' />
      </div>
      <div className={style.footer__tate}>
        <img src={tate} alt='' />
      </div>
      <div className={style.footer__quote}>
        <img src={quote} alt='' />
      </div>

      <a href='mailto:Team@DORKU.io' className={style.footer__mail}>
        Team@DORKU.io
      </a>

      {/*<a*/}
      {/*  href={whitepaperPDF}*/}
      {/*  target='_blank'*/}
      {/*  className={style.footer__whitepaper}*/}
      {/*>*/}
      {/*  whitepaper*/}
      {/*</a>*/}
    </footer>
  );
};

export default Footer;
