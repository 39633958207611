import Header from './components/Header';
import DorkuSection from './modules/Dorku';
import AboutSection from './modules/About';
import MainSection from './modules/Main';
import TimerSection from './modules/Timer';
import TokenomicsSection from './modules/Tokenomics';
import RoadmapSection from './modules/Roadmap/Roadmap';
import Footer from './components/Footer';
import Modal from './components/Modal';
import {useEffect, useState} from "react";

function App() {
    const [mainMuted, setMainMuted] = useState(false);
    const [youtube1IsPlaying, setYoutube1IsPlaying] = useState(false);
    const [youtube2IsPlaying, setYoutube2IsPlaying] = useState(false);

    useEffect(() => {
        if (youtube1IsPlaying || youtube2IsPlaying) {
            setMainMuted(true);
        } else {
            setMainMuted(false);
        }
    }, [youtube1IsPlaying, youtube2IsPlaying])

    return (
        <div className={`wrapper`}>
            <Modal/>

            <div className={`content`}>
                <Header/>
                <MainSection mainMuted={mainMuted}/>
                <DorkuSection/>
                <TimerSection onClick1={(play) => setYoutube1IsPlaying(play)}
                              onClick2={(play) => setYoutube2IsPlaying(play)}
                />
                <AboutSection/>
                <TokenomicsSection/>
                <RoadmapSection/>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
