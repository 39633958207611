import style from './Header.module.scss';
import { socialLinksHeader } from './socialLinks';
import logo from './assets/logo.png';
import logo2x from './assets/logo@2x.png';
import line from './assets/line@2x.png';
import dextools from './assets/dextools.png';
import line2x from './assets/line@2x.png';
import lineMobile from './assets/line-mobile.png';
import { useMediaQuery } from 'usehooks-ts';

const Header = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <header className={style.header}>
      <div className={style.header__line}>
        {isMobile ? (
          <img src={lineMobile} alt='' />
        ) : (
          <img src={line} srcSet={`${line} 1x, ${line2x} 2x`} alt='' />
        )}
      </div>
      <a
        href='https://www.dextools.io/app/en/hot-pairs'
        target='_blank'
        rel='noreferrer'
        className={style.header__dex}
      >
        <img src={dextools} alt='' />
      </a>
      <div className='container'>
        <div className={style.headerBody}>
          <div className={style.headerBody__logo}>
            <img src={logo} srcSet={`${logo} 1x, ${logo2x} 2x`} alt='' />
          </div>
        </div>
      </div>
      <div className={style.headerBody__social}>
        {socialLinksHeader.map((item, index) => (
          <a
            key={index}
            href={item.href}
            target='_blank'
            rel='noreferrer'
            className={style.headerBody__socialItem}
          >
            <img
              src={item.icon[0]}
              srcSet={`${item.icon[0]} 1x, ${item.icon[1]} 2x`}
              alt=''
            />
          </a>
        ))}
      </div>
    </header>
  );
};

export default Header;
