import style from './Timer.module.scss';
import img from './assets/bg_img.png';
import imgMobile from './assets/bg_img-mobile.png';
import imgTate from './assets/tate.png';
import imgTateMobile from './assets/tate-mobile.png';
import imgAnime from './assets/anime.png';
import title from './assets/title.png';
import aboutTitle from '../About/assets/title.png';
import label from './assets/timer_text.png';
import buy from './assets/buy_text.png';
import pinksale from './assets/pinksale.png';
import eth from './assets/eth_text.png';
import line from './assets/line.png';
import {useTimer} from 'react-timer-hook';
import {useMediaQuery} from 'usehooks-ts';
import ReactPlayer from 'react-player/youtube'

const TimerSection = ({onClick1, onClick2}) => {
    const expiryTimestamp = new Date(1699727400 * 1000);
    const isMobile = useMediaQuery('(max-width:767px)');

    const {
        totalSeconds,
        seconds,
        minutes,
        hours,
        days,
        isRunning,
        start,
        pause,
        resume,
        restart,
    } = useTimer({
        expiryTimestamp,
        onExpire: () => console.warn('onExpire called'),
    });

    function calcMinutes(start, finish) {
        return (
            (new Date(finish).getTime() - new Date(start).getTime()) / 60000 / 60
        );
    }

    let minutesDiv = calcMinutes(1699568093043, 1699727400 * 1000);

    // console.log(Date.now() );

    return (
        <section className={style.timer}>
            <div className={style.timer__aboutTitle}>
                <img src={aboutTitle} alt=''/>
            </div>

            <div className={style.timer__img}>
                <img src={isMobile ? imgMobile : img} alt=''/>
            </div>
            <div className={style.timer__tate}>
                <img src={isMobile ? imgTateMobile : imgTate} alt=''/>
            </div>
            <div className={style.timer__anime}>
                <img src={imgAnime} alt=''/>
            </div>


            <div className={style.timerBody}>
                <div className={style.timerBodyWrapper}>
                    <ReactPlayer url={"https://www.youtube.com/embed/OvE8XxJI7WU?si=1oEGigK43A0fKMqK"}
                                 width={"100%"}
                                 height={"100%"}
                                 onPlay={() => {
                                     onClick1(true)
                                 }}
                                 onPause={() => {
                                     onClick1(false)
                                 }}
                                 //ref={ref}

                    />
                </div>

                <div className={style.timerBodyWrapper}>
                    <ReactPlayer url={"https://www.youtube.com/embed/AqihiSbj8ac?si=Ak03GJ86bH8bNbGu"}
                                 width={"100%"}
                                 height={"100%"}
                                 onPlay={() => {
                                     console.log("play")
                                     onClick2(true)
                                 }}
                                 onPause={() => {
                                     console.log("pause")
                                     onClick2(false)
                                 }}

                    />
                </div>

                {/*<iframe*/}
                {/*    src='https://www.youtube.com/embed/OvE8XxJI7WU?si=1oEGigK43A0fKMqK'*/}
                {/*    title='YouTube video player'*/}
                {/*    frameBorder='0'*/}
                {/*    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'*/}
                {/*    allowFullScreen*/}
                {/*/>*/}

                {/*<iframe*/}
                {/*    onClick={() => onClick2()}*/}
                {/*    src='https://www.youtube.com/embed/AqihiSbj8ac?si=Ak03GJ86bH8bNbGu'*/}
                {/*    title='YouTube video player'*/}
                {/*    frameborder='0'*/}
                {/*    allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'*/}
                {/*    allowfullscreen*/}
                {/*/>*/}


                {/* <div className={style.timerBody__title}>
          <img src={title} alt='' />
        </div>
        <div className={style.timerBody__item}>
          {days.toString().padStart(2, 0)}:{hours.toString().padStart(2, 0)}:
          {minutes.toString().padStart(2, 0)}:
          {seconds.toString().padStart(2, 0)}
        </div>
        <div className={style.timerBody__label}>
          <img src={label} alt='' />
        </div>
        <div className={style.timerBody__buy}>
          <img src={buy} alt='' />
        </div>
        <a
          href='https://www.pinksale.finance/launchpad/0xC307537bE0853b89701E3BF74ed086c5D64948C5?chain=ETH&refId=0xFAa63f738D83D8678Ee45c69B68cb9B3bdF70a71'
          target='_blank'
          rel='noreferrer'
          className={style.timerBody__pinksale}
        >
          <img src={pinksale} alt='' />
        </a>
        <div className={style.timerBody__eth}>
          <img src={eth} alt='' />
        </div>
        <div className={style.timerBody__line}>
          <span
            style={{
              width: `${
                100 - 2.28 * Math.round(minutesDiv) < 0
                  ? 0
                  : 100 - 2.28 * Math.round(minutesDiv)
              }%`,
            }}
          ></span>
        </div> */}
            </div>
        </section>
    );
};

export default TimerSection;
