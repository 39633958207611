import { useMediaQuery } from 'usehooks-ts';
import style from './Roadmap.module.scss';
import bg from './assets/bg.png';
import bgFirst from './assets/bg-first.png';
import bgMobile from './assets/bg-mobile.png';
import bgFirstMobile from './assets/bg-first-mobile.png';
import title from './assets/title.png';
import quote from './assets/quote.png';
// import quoteFirst from './assets/quote-first.png';
import text from './assets/text.png';

const RoadmapSection = () => {
  const isMobile = useMediaQuery('(max-width:767px)');

  return (
    <>
      <section className={style.roadmapFirst}>
        <div className={style.roadmapFirst__img}>
          <img src={isMobile ? bgFirstMobile : bgFirst} alt='' />
        </div>
        <div className={style.roadmapFirst__title}>
          <img src={title} alt='' />
        </div>
        {!isMobile && (
          <div className={style.roadmapFirst__quote}>
            <img src={quote} alt='' />
          </div>
        )}
        {/* <div className={style.roadmapFirst__quote}>
          <img src={quoteFirst} alt='' />
        </div> */}
      </section>
      <section className={style.roadmap}>
        {isMobile && (
          <div className={style.roadmap__quote}>
            <img src={quote} alt='' />
          </div>
        )}
        <div className={style.roadmap__img}>
          <img src={isMobile ? bgMobile : bg} alt='' />
        </div>
        <div className={style.roadmap__text}>
          <img src={text} alt='' />
        </div>
      </section>
    </>
  );
};

export default RoadmapSection;
