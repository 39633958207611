import tgIcon from './assets/telegram.png';
import tgIcon2x from './assets/telegram@2x.png';
import twitterIcon from './assets/twitter.png';
import twitterIcon2x from './assets/twitter@2x.png';
import favIcon from './assets/favicon.png';
import favIcon2x from './assets/favicon@2x.png';

export const socialLinksHeader = [
  {
    href: 'https://t.me/thedorkuportal',
    icon: [tgIcon, tgIcon2x],
  },
  {
    href: 'https://x.com/DORKU_TRON',
    icon: [twitterIcon, twitterIcon2x],
  },
  {
    href: '#',
    icon: [favIcon, favIcon2x],
  },
];
